import React from 'react'

import { account } from '../../../store.config'
import useCmsInstitutional from '../../hooks/useCmsInstitutional'
import { NpmInstitutionalPage } from '../../local_modules'
import { formatAccountName } from '../../local_modules/utils/formatAccountName'

import '../../styles/pages/cookiePolicy.scss'

const CookiePolicy = () => {
  const { cookiePolicyBanners } = useCmsInstitutional()
  const brand = formatAccountName(account)

  return (
    <NpmInstitutionalPage
      title="Politica de cookie"
      banners={cookiePolicyBanners}
    >
      <div className="cookiePolicy-container">
        <p>
          A {brand} reconhece que a sua privacidade é importante, e por isso
          queremos ser transparentes sobre como utilizamos cookies para melhorar
          a sua experiência. Nesta página, você será direcionado ao nosso Aviso
          de Cookies, onde explicamos detalhadamente como essas tecnologias
          funcionam, quais tipos de cookies utilizamos, e como você pode
          gerenciar suas preferências. Acesse o aviso completo para entender
          como protegemos suas informações e como você pode ter um maior
          controle sobre seus dados enquanto navega em nosso site.
        </p>

        <h1 style={{ marginTop: '10px' }}>
          <strong>Política de Privacidade e Proteção de Dados</strong>
        </h1>
        <div className="privacy-container">
          <p>
            Esta Política descreve como a COMPANHIA SULAMERICANA DE DISTRIBUIÇÃO
            ("CSD"), sociedade anônima de capital fechado, inscrita no CNPJ/MF
            sob nº. 11.517.841/0001-97, com sede na Avenida Carlos Correia
            Borges, nº. 1188, Jardim Guaporé, na cidade de Maringá, Estado do
            Paraná, CEP 87.060-000, assumindo o papel de Controladora, coleta,
            mantém, compartilha e utiliza os seus dados pessoais, bem como os
            direitos que você possui, nos termos das leis aplicáveis, em
            especial a Lei n.º 13.709/2018 ("Lei Geral de Proteção de Dados
            Pessoais").
          </p>

          <p>
            NA CSD, temos a preocupação constante e compromisso com a proteção
            dos dados pessoais utilizados em nossos serviços. A presente
            Política de Privacidade detalha informações transparentes e claras
            sobre o tratamento de dados pessoais e as formas como protegemos os
            dados e seus titulares.
          </p>

          <h2>
            <strong>Quais dados pessoais coletamos?</strong>
          </h2>
          <p>
            Coletamos as informações necessárias para cumprir com o fornecimento
            dos nossos produtos e serviços e dependerá da interação que você tem
            com eles.
          </p>
          <p>
            O termo "dados pessoais" por diversas vezes é descrito nesta
            Política. Este termo diz respeito a todas as informações
            relacionadas ao seu respeito e que são capazes de identificá-lo,
            tais como:
          </p>

          <ul>
            <li>Nome</li>
            <li>Número de Cadastro da Pessoa Física (“CPF”)</li>
            <li>Número do Registro Geral (“RG”)</li>
            <li>Data de nascimento</li>
            <li>Endereço de e-mail</li>
            <li>Endereço de entrega e cobrança</li>
            <li>Telefone de contato</li>
            <li>Sexo</li>
            <li>Dados de cobrança</li>
            <li>
              Imagens coletadas por meio de câmeras de vigilância e
              monitoramento da circulação de pessoas em nossos estabelecimentos
            </li>
          </ul>

          <p>
            Também realizamos a coleta de dados pessoais de forma automática,
            quando você faz uso do site, tais como cookies e outras informações
            que constam descritas na barra específica disponível no site.
          </p>

          <h2>
            <strong>
              Para quais finalidades utilizamos os dados pessoais coletados?
            </strong>
          </h2>
          <ul>
            <li>
              Prestação dos Serviços: processamento de compras, devolução e
              troca de produtos, realização de parcelamentos, realização de
              entregas via delivery, emissão de nota fiscal, entre outros.
            </li>
            <li>
              Inteligência de mercado: análise de inteligência de negócio para
              estudo de mercado, definição e análise de padrões e preferências
              de consumo dos nossos clientes.
            </li>
            <li>Clube+: programa de benefícios da CSD.</li>
            <li>Atendimento às solicitações.</li>
            <li>Prevenção à fraude.</li>
            <li>Segurança Patrimonial.</li>
            <li>Canais de atendimento próprios.</li>
            <li>Obrigações legais.</li>
            <li>Internet Wi-Fi.</li>
          </ul>

          <h2>
            <strong>
              Como são utilizados cookies e tecnologias similares?
            </strong>
          </h2>
          <ul>
            <li>
              <strong>Cookies Necessários:</strong> essenciais para o
              funcionamento da plataforma eletrônica.
            </li>
            <li>
              <strong>Cookies Analíticos:</strong> fornecem informações sobre
              como o site está sendo usado.
            </li>
            <li>
              <strong>Cookies Funcionais:</strong> personalização aprimorada.
            </li>
            <li>
              <strong>Cookies de Marketing:</strong> informações sobre a
              interação do usuário com o conteúdo do site.
            </li>
          </ul>

          <h2>
            <strong>Por quanto tempo armazenamos esses dados pessoais?</strong>
          </h2>
          <p>
            A política de retenção de dados pessoais pela CSD respeita as
            seguintes diretrizes:
          </p>
          <ul>
            <li>
              Somente são mantidos em nossos sistemas os dados pessoais
              estritamente necessários.
            </li>
            <li>
              A qualquer momento é possível solicitar a exclusão dos dados
              pessoais.
            </li>
          </ul>

          <h2>
            <strong>
              Quando os dados pessoais são compartilhados com terceiros?
            </strong>
          </h2>
          <p>
            Compartilhamos as suas informações somente com parceiros e
            fornecedores autorizados.
          </p>

          <h2>
            <strong>
              Como garantimos a segurança dos seus dados pessoais?
            </strong>
          </h2>
          <p>
            A CSD adota políticas, mecanismos e procedimentos de segurança
            existentes no mercado.
          </p>

          <h2>
            <strong>Quais são os seus direitos e solicitações?</strong>
          </h2>
          <ul>
            <li>Solicitar o acesso aos seus Dados Pessoais;</li>
            <li>Solicitar a correção dos seus Dados Pessoais;</li>
            <li>Solicitar a exclusão dos seus Dados Pessoais;</li>
            <li>Opor-se ao tratamento dos seus Dados Pessoais;</li>
            <li>
              Solicitar informação sobre o compartilhamento dos seus Dados
              Pessoais;
            </li>
            <li>Revogar um consentimento dado anteriormente.</li>
          </ul>

          <h2>
            <strong>Em caso de dúvidas, com quem você pode falar?</strong>
          </h2>
          <p>
            Se você acredita que seus Dados Pessoais foram usados de maneira
            incompatível com este Aviso de Privacidade, entre em contato
            conosco.
          </p>
          <p>
            <strong>DPO (encarregado):</strong> Vladimir Fortes Santos
          </p>
          <p>
            <strong>Endereço para correspondências:</strong> Av. Carlos Correa
            Borges, 1188 - Conj. Hab. Inocente Vila Nova Júnior, Maringá - PR,
            87060-000
          </p>
          <p>
            <strong>Canal de atendimento:</strong>{' '}
            <a href="mailto:dpo@csdvarejo.com.br">dpo@csdvarejo.com.br</a>
          </p>

          <h2>
            <strong>Alterações neste Aviso</strong>
          </h2>
          <p>
            A presente Política de Privacidade poderá sofrer atualizações e
            ajustes a qualquer tempo.
          </p>
          <p>
            <strong>Documento atualizado em 12 de maio de 2021.</strong>
          </p>
          <p>
            <strong>COMPANHIA SULAMERICANA DE DISTRIBUIÇÃO</strong>
          </p>
        </div>
      </div>
    </NpmInstitutionalPage>
  )
}

export default CookiePolicy
